.fab {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #007bff; 
    border: none;
    border-radius: 50%;
    width: 56px; 
    height: 56px;
    text-align: center;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.floatingbutton {
    display: block;
    color: #fff;
    text-decoration: none;
    line-height: 56px; 
}

.fab:hover {
    background-color: #0056b3; 
}